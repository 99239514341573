import React from "react";
import OfferData from './OfferData';
import {Link} from 'react-router-dom';

const Offer = () =>{
    console.log(OfferData);
    return(
        <>
        <div className="offer-box-sec spacer">
            <div className="container">
                <div className="row">
                {OfferData.map((OfferItem, index) => (
                        <div className="col-md-4" key={index}>
                    <div className="offer-box" data-aos="fade-up" data-aos-duration="1500">
                            <div className="ob-img-wrp">
                                <figur role="none">
                                <img src={OfferItem.icon} alt="Custom Plugin Development" />
                                </figur>
                            </div>
                            <div className="ob-title-wrp">
                                <h3>{OfferItem.title}</h3>
                            </div>
                            <div className="ob-cont-wrp">
                                <p>{OfferItem.description}</p>
                            </div>
                        </div>
                    </div>
                    ))}
                    <div class="offer-btn-wrp" data-aos="zoom-in" data-aos-duration="1500">
                        <Link to="/contact-us/" aria-label="Read More" class="btn-11">GET IN TOUCH With Us</Link>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Offer;