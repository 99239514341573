import React from 'react';
import Our_progress_Data from '../js/Json/Our-progress';

const Our_progress = () => {
    return(
        <>
        <section className="our-progress-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="our-progress-wrp">
                            <div className="progress-title-wrp">
                                <h2 className="common-title" data-aos="fade-up" data-aos-duration="2000">Our Progress</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="op-box-sec">
                    <div className="row">
                        {Our_progress_Data.map((progress_data, index) => (
                            <>
                            <div className="col-md-4">
                            <div className="op-box">
                                <div className="op-img-wrp">
                                    <figure role="none" data-aos="fade-up" data-aos-duration="2000" data-aos-delay={index * 700}>
                                    <img src={progress_data.icon} alt={progress_data.title} width="70" height="71"/>
                                    </figure>
                                </div>
                                <div className="op-title-wrp" data-aos="fade-up" data-aos-duration="2000" data-aos-delay={index * 700}>
                                    <h3>{progress_data.title}</h3>
                                </div>
                                <div className="op-cont-wrp" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay={index * 700}>
                                    <p>{progress_data.description}</p>
                                </div>
                            </div>
                        </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Our_progress;