import React from "react";
import {Link} from 'react-router-dom';
import ProcessArrow1 from '../../../assets/images/process-arrow-1.webp';
import ProcessArrow2 from '../../../assets/images/process-arrow-2.webp';
import ProcessArrow3 from '../../../assets/images/process-arrow-3.webp';
import ProcessArrow4 from '../../../assets/images/process-arrow-4.webp';
import ProcessArrow5 from '../../../assets/images/process-arrow-6.webp';

const Process = () => {
    return(
        <>
        <div className="process-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="process-inner-wrp">
                            <h2 data-aos="zoom-in" data-aos-duration="1500">THE PROCESS THAT WE FOLLOW IS SMOOTH EASY AND EFFECTIVE TO EXECUTE A PROJECT TILL ITS DELIVERED TO THE CLIENT WE FOLLOW THE BEST PRACTISES</h2>
                        <div className="parrow-1-wrp">
                        <figure role="none" data-aos="zoom-in" data-aos-duration="1500">
                            <img src={ProcessArrow1} alt="Process Arrow" class="parrow-1"/>
                        </figure>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="process-wrapper">
                <div className="row">
                    <div className="col-md-5">
                        <div className="process-cont-box" data-aos="zoom-in" data-aos-duration="1500">
                            <h3 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">REQUIREMENT GATHERING</h3>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">We understand your need and the requirement that will support your business to grow.</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="parrow-box parrow-common-box">
                            <figure role="none" className="arrow-2" data-aos="zoom-in" data-aos-duration="1000">
                                <img src={ProcessArrow2} alt="Process Arrow" />
                            </figure>
                            <figure role="none" className="mob-arrow-3">
                                <img src={ProcessArrow3} alt="Process Arrow" />
                            </figure>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="process-cont-box" data-aos="zoom-in" data-aos-duration="1500">
                            <h3  data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">TIMELINE</h3>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">You'll be aware through the project about all the dates and deliverables.</p>
                        </div>
                    </div>
                </div>
                </div>

                <div className="row">
                    <div className="col-md-5"></div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                    <div className="parrow-box parrow-3-wrp">
                            <figure role="none" data-aos="fade-down" data-aos-duration="1500">
                                <img src={ProcessArrow3} alt="Process Arrow" />
                            </figure>
                    </div>
                    </div>
                </div>

                <div className="process-wrapper">
                <div className="row">
                    <div className="col-md-5">
                        <div className="process-cont-box" data-aos="zoom-in" data-aos-duration="1500">
                            <h3 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">DESIGNING</h3>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">We have your sitemap ready and you will be able to see your design of the pages and your website takes shape. Multiple review rounds to get the best output and your approval.</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="parrow-box parrow-common-box">
                            <figure role="none" class="arrow-2" data-aos="zoom-in" data-aos-duration="1500">
                                <img src={ProcessArrow4} alt="Process Arrow" />
                            </figure>
                            <figure role="none" className="mob-arrow-3">
                                <img src={ProcessArrow3} alt="Process Arrow" />
                            </figure>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="process-cont-box" data-aos="zoom-in" data-aos-duration="1500">
                            <h3 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">PLANNING SITEMAP</h3>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">We will help you in getting your sitemap done with some wireframe creation.</p>
                        </div>
                    </div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-5">
                    <div className="parrow-box parrow-3-wrp">
                            <figure role="none" data-aos="fade-down" data-aos-duration="1500">
                                <img src={ProcessArrow3} alt="Process Arrow" />
                            </figure>
                            
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5"></div>
                </div>

                <div className="process-wrapper">
                <div className="row">
                    <div className="col-md-5">
                        <div className="extra-box">
                        <div className="process-cont-box" data-aos="zoom-in" data-aos-duration="1500">
                            <h3 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">DEVELOPMENT</h3>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">Our skilled developers will develop the site using the latest code structure to lighten the site.</p>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="parrow-box parrow-common-box">
                            <figure role="none" className="arrow-2" data-aos="zoom-in" data-aos-duration="1500">
                                <img src={ProcessArrow2} alt="Process Arrow" />
                            </figure>
                            <figure role="none" className="mob-arrow-3">
                                <img src={ProcessArrow3} alt="Process Arrow" />
                            </figure>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="process-cont-box" data-aos="zoom-in" data-aos-duration="1500">
                            <h3 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">TESTING, REVIEW AND LAUNCH</h3>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">Our skilled Quality Analyst will deep dive into the site and ensure that there are no bugs left. Then they provide green signal and pass it for launch. The multiple rounds of review will allow us to polish the site and make it more
                            wonderful as expected.</p>
                        </div>
                    </div>
                </div>
               </div>

               <div className="row">
                <div class="col-md-4"></div>
                <div className="col-md-8">
                <div className="parrow-7-wrp">
                    <figure role="none" data-aos="zoom-in" data-aos-duration="1500">
                        <img src={ProcessArrow5} alt="Process Arrow"/>
                    </figure>
                </div>
                </div>
               </div>

               <div className="process-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="process-cont-box p-extra-cont-box" data-aos="fade-up" data-aos-duration="1500">
                            <h3 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">TESTING, REVIEW AND LAUNCH</h3>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">Our skilled Quality Analyst will deep dive into the site and ensure that there are no bugs left. Then they provide green signal and pass it for launch. The multiple rounds of review will allow us to polish the site and make it more
                            wonderful as expected.</p>
                        </div>

                        <div className="process-btn-wrp" data-aos="fade-up" data-aos-duration="1500">
                            <Link to="/contact-us/" className="primary-btn">Hire Wordpress Experts</Link>
                        </div>
                    </div>
                </div>
            </div>

            </div>
        </div>
        </>
    )
}

export default Process;