import React, { useState, useEffect } from "react";
import './SinglePost.css';
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Loader from '../../assets/images/loader.gif';

const Single = () => {

    const [post, setPost] = useState(null);
    const location = useLocation();
  
    // Parse the query string to get the postId
    const { postId } = queryString.parse(location.search);
  
    useEffect(() => {
      if (postId) {
        fetch(`https://sohail.themefantasy.com/themefantasy/wp-json/wp/v2/posts/${postId}`)
          .then((response) => response.json())
          .then((data) => setPost(data))
          .catch((error) => console.error("Error fetching post:", error));
      }
    }, [postId]);
    if (!post) return <div className="loader-box"><figure role="none"><img src={Loader} /></figure></div>;
    return(
        <>
        {!post ? ( 
      <div className="loader-box">
        <figure role="none">
          <img src={Loader} alt="Loading..." />
        </figure>
      </div>
    ) : (
        <>
      <div className="singlePost-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="single-post-wrp banner-inner-wrp">
                <h1>{post.title.rendered}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="single-post-wrp">
        <div className="container">
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="post-cont-wrp">
                        <figure role="none">
                            <img src={post.jetpack_featured_media_url} alt={post.title.rendered} />
                        </figure>
                        <div className="single-post-content">
                            <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
                        </div>
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
        </div>
      </div>
      </>
    )}
        </>
    )
}

export default Single;