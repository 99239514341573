import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import Loader from '../../../assets/images/loader.gif';

const Form = () => {
  const form = useRef();
  
const sendEmail = (e) => {
e.preventDefault();
const loaderWrapper = document.createElement("div");
loaderWrapper.className = "loader-wrp";

const figure = document.createElement("figure");
figure.setAttribute("role", "none");

const img = document.createElement("img");
img.src = Loader;
img.alt = "Loader";

figure.appendChild(img);

loaderWrapper.appendChild(figure);

document.body.appendChild(loaderWrapper);

    emailjs.init('OKbpbPWqmDuYhFdL7');
    
// Send the notification email to your team
      emailjs
      .sendForm('service_2qbqycr', 'template_0epdz35', form.current, 'OKbpbPWqmDuYhFdL7')
      .then(() => {
        // After sending the first email, send the auto-reply
        emailjs
          .sendForm('service_2qbqycr', 'template_79pum3o', form.current, 'OKbpbPWqmDuYhFdL7')
          .then(() => {
            // Select the loader element by class name
const loaderWrapper = document.querySelector(".loader-wrp");

// Check if the element exists, then remove it
if (loaderWrapper) {
  loaderWrapper.remove();
}
            Swal.fire({
              title: "Submitted successfully",
              text: "Thank you for reaching out to us! We have received your message and will get back to you as soon as possible.",
              icon: "success"
            });
          })
          .catch((error) => {
            console.log('Failed to send auto-reply...', error.text);
          });
      })
      .catch((error) => {
        console.log('Failed to send notification email...', error.text);
      });
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}> 
        <div className="contact-form-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <input type="text" name="your_name" placeholder="Your Name" data-aos="fade-up" data-aos-duration="1500" required />
                <input type="text" name="company_name" placeholder="Company Name" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500" required />
              </div>
              <div className="col-md-6">
                <input type="email" name="email" placeholder="E-mail" data-aos="fade-up" data-aos-duration="1500" required />
                <input type="tel" name="phone_number" placeholder="Phone Number" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500" required />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <textarea name="message" placeholder="Your Message" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" required />
                <label data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">Budget</label>
                <input type="number" name="budget" placeholder="How much has been budgeted for this project?" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1500" required />
                <label data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">TimeLine</label>
                <input type="date" name="due_date" placeholder="Due Date"  data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500" required />
                
                <div className="form-btn-wrp" data-aos="fade-up" data-aos-duration="1500">
                  <button type="submit">Send Message</button>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
