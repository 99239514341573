import React from 'react';
import './templates/Contact.css';
import Banner from './templates/Banner';
import Form from './templates/Form';
import ConnectUs from './templates/Connect-With-Us';
const Contact = () => {
    return(
        <>
        <Banner />
        <ConnectUs />
        <Form />
        </>
    )
}

export default Contact;