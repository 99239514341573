const Reviews = [
    {
        username:"Georgie Smith",
        description:"I have been collaborating with Sabir on many design projects over the last 5 years. I am a perfectionist with graphic design and Sabir and me have always",
        profile:require('../../../assets/images/Georgie-Smith.png'),
    },
    {
        username:"Made Santika",
        description:"I have problem with my ecommerce site, and i ask this guy to fix, its awesome support, thank you very much",
        profile:require('../../../assets/images/Made-Santika.png'),
    },
    {
        username:"Sofia Badyari",
        description:"Very efficient management and services. Very helpful and clear",
        profile:require('../../../assets/images/Sofia-Badyari.png'),
    },
    {
        username:"Huma Kiyani",
        description:"They are really helpful 😁 I appreciate them😊",
        profile:require('../../../assets/images/Sofia-Badyari.png'),
    }
]

export default Reviews;