import React from "react";
import {Link} from 'react-router-dom';

const NoPage = () => {
    return(
        <>
            <div className="pnt-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pnt-cont-wrp">
                                <h1>Uh-oh, Something Went Wrong!</h1>
                                <h2>404 - Page Not Found</h2>
                                <p>It seems the page you're looking for has gone on vacation or never existed in the first place.</p>
                                <p>Head back to our <Link to="/">homepage</Link> to start fresh.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NoPage;