import React from "react";

const Banner = () => {
    return(
        <>
        <div className="all-service-banner-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="banner-inner-wrp">
                            <h1 data-aos="fade-up" data-aos-duration="1500">What we offer</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="about-sec spacer">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="about-cont">
                            <p data-aos="fade-up" data-aos-duration="1500">Themefantasy is a full-stack development agency that focuses on developing WordPress and WooCommerce. We offer top-of-the-line WordPress/WooCommerce solutions that include custom plugin/extension development, theme development, back-end development, front-end development. Our list of services also includes WordPress SaaS development, speed optimization & caching, SEO services, uptime monitoring, security, and malware removal, code reviews, database audit, performance trouble- shooting, API programming, payment gateway integration, website/store migration, and WordPress development retainers.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Banner;