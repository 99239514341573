import React from 'react';
import Offer_Data from '../js/Json/Offer';

const Offer = () => {

    return(
        <>
        <section className="offer-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="offer-cont-wrp">
                            <h2 class="common-title" data-aos="fade-up" data-aos-duration="2000">What We Offer</h2>
                            <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">We are a full-stack development company with an agile approach. Our happy engineers, developers, and service-providing agents work round the clock to deliver complex projects on tight deadlines. Here is a list of the services we offer.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="offer-box-sec spacer">
            <div className="container-fluid">
                <div className="row">
                    {Offer_Data.map((Offer_Item, index) => (
                        <div className="col-md-3">
                        <div className="offer-box">
                            <div className="ob-img-wrp">
                            <figure role="none" data-aos="fade-up" data-aos-duration="2000" data-aos-delay={index * 700}>
                                <img src={Offer_Item.icon} alt={Offer_Item.title} width="75" height="73"/>
                            </figure>
                            </div>
                            <div className="ob-title-wrp" data-aos="fade-up" data-aos-duration="2000" data-aos-delay={index * 700}>
                                <h3>{Offer_Item.title}</h3>
                            </div>
                            <div className="ob-cont-wrp" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay={index * 700}>
                                <p>{Offer_Item.description}</p>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </section>
        </>
    )
}

export default Offer;