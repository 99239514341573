import React from "react";

const Banner = () =>{
    return(
        <>
        <div className="contact-banner-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="banner-inner-wrp">
                            <h1 data-aos="fade-up" data-aos-duration="1500">Contact Us</h1>
                            <h2 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">Refer To The Our Professionals</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Banner;