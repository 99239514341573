import React, { useEffect, useRef } from "react";
import Typed from "typed.js";

const Banner = () => {
    const typedElement = useRef(null);

    useEffect(() => {
        const typed = new Typed(typedElement.current, {
            strings: ["Hire a WordPress", "developer"], 
            typeSpeed: 100,  
            backSpeed: 80,  
            backDelay: 1000,  
            loop: true, 
        });

        return () => {
            typed.destroy();
        };
    }, []);

    return(
        <>
            <section className="banner-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-cont-wrp">
                                <h1 data-aos="fade-up" data-aos-duration="2000"><span ref={typedElement}></span></h1>
                                <p data-aos="zoom-in" data-aos-duration="2000">from our compant to convert your WordPress Development idea into Reality</p>
                                <div className="btn-wrp" data-aos="zoom-in" data-aos-delay="500" data-aos-duration="1000">
                                    <a href="/" aria-label="Read More" class="btn-11">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about-sec spacer'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div class="about-cont">
                                <p data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="500">Founded in 2011, Themefantasy is specializing in WordPress, WooCommerce, Manneto, and Shopify full-stack development, We've been working with WordPress & WooCommerce platform for more than a decade and already won 500+ clients worldwide.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner;
