import React from 'react';
import {Link} from 'react-router-dom';
const Banner = () => {
    return(
        <>
        <section className="services-banner-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="banner-inner-wrp">
                        <h1 data-aos="fade-up" data-aos-duration="1500">Service <span>Wordpress</span></h1>
                        <Link to="/contact-us/" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="primary-btn">Get a Qoute</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Banner;