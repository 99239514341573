import React from "react";
import {Link} from 'react-router-dom';

const Wordpress = () => {
    return(
        <>
        <section className="wordpress-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="wordpress-inner-wrp">
                            <h2 className="common-title" data-aos="fade-up" data-aos-duration="1500">Wordpress</h2>
                            <p  data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus- pendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <Link to="/contact-us/" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="primary-btn">Get a Qoute</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Wordpress;