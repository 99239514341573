import React from "react";
import './templates/About.css';
import Banner from './templates/Banner';
import SocialMedia from './templates/Social-Media';
import Process from './templates/Process';

const About_us = () => {
    return(
        <>
        <Banner />
        <SocialMedia />
        <Process />
        </>
    )
}

export default About_us;