import React from "react";
import './templates/Services-style.css';
import Banner from './templates/Banner';
import Wordpress from './templates/Wordpress';
import CustomerLove from '../Home-includes/templates/Customer';
import CustomerReviews from '../Home-includes/templates/Reviews';
import Portfolio from '../Home-includes/templates/Our-Client';

const Services = () => {
    return(
        <>
        <Banner />
        <Wordpress />
        <CustomerLove />
        <CustomerReviews />
        <Portfolio />
        </>
    )
}

export default Services;