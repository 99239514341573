const Our_clients = [
    {
        'logo':require('../../../../assets/images/client-1.webp'),
        'alt':'Client 1',
    },
    {
        'logo':require('../../../../assets/images/client-2.webp'),
        'alt':'Cakes nmore',
    },
    {
        'logo':require('../../../../assets/images/client-3.webp'),
        'alt':'Ficlame',
    },
    {
        'logo':require('../../../../assets/images/client-4.webp'),
        'alt':'Mohsin Al Sale',
    },
];

export default Our_clients;