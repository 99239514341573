import React, { useState, useEffect, useRef } from "react";

const Counter = ({ value }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = parseInt(value.replace(/[^0-9]/g, "")); 
    if (start === end) return;

    const increment = Math.ceil(end / 100); 
    const timer = setInterval(() => {
      start += increment;
      if (start >= end) {
        clearInterval(timer);
        start = end;
      }
      setCount(start);
    }, 30); 

    return () => clearInterval(timer);
  }, [value]);

  return <h4>{value.includes("%") || value.includes("+") ? `${count}${value.replace(/[0-9]/g, "")}` : count}</h4>;
};

const Services = () => {
  const [startCount, setStartCount] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true);
        }
      },
      { threshold: 0.5 } 
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <section className="services-sec spacer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="services-cont-wrp">
                <div className="services-title-wrp" data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-in-sine">
                  <h2 className="common-title">5+ Years of <br />committed <br />Service</h2>
                </div>
                <div className="services-cont" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="500">
                  <p>We are a passionate digital design agency that specializes in beautiful and easy-to-use digital design & development services</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="services-count-wrp" ref={ref}>
                <ul>
                  <li data-aos="flip-up" data-aos-duration="700" data-aos-delay="400">
                    {startCount ? <Counter value="650" /> : <h4>0</h4>}
                    <p>Projects</p>
                  </li>
                  <li data-aos="flip-up" data-aos-duration="700" data-aos-delay="400">
                    {startCount ? <Counter value="100%" /> : <h4>0%</h4>}
                    <p>Client Retention</p>
                  </li>
                  <li data-aos="flip-up" data-aos-duration="700" data-aos-delay="400">
                    {startCount ? <Counter value="60+" /> : <h4>0+</h4>}
                    <p>Years of Domain <br /> Expertise</p>
                  </li>
                  <li data-aos="flip-up" data-aos-duration="700" data-aos-delay="400">
                    {startCount ? <Counter value="30+" /> : <h4>0+</h4>}
                    <p>Experts</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
