import React from "react";
import Slider from "react-slick";
import Our_client_Data from '../js/Json/Our-clients';

const Our_client = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive:[
            {
                breakpoint:992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint:768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return(
        <>
        <section className="our-client-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="our-client-inner-wrp">
                            <h2 className="common-title" data-aos="fade-up" data-aos-duration="2000">Our Client</h2>
                        </div>
                        <div className="our-client-slider-wrp">
                        <Slider {...settings}>
                            {Our_client_Data.map((Client_item, index) => (
                                <div className="client-slider-item" data-aos="flip-right" data-aos-duration="1000" data-aos-delay={index * 500}>
                                    <figure role="none" className="client-img">
                                        <img src={Client_item.logo} alt={Client_item.alt} width="150" height="150"/>
                                    </figure>
                                </div>
                            ))}
                        </Slider>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
        </>
    )
}

export default Our_client;