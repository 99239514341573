import React from 'react';
import './templates/home.css';
import Banner from './templates/Banner';
import Offer from './templates/Offer';
import Our_progress from './templates/Our-Progress';
import Services from './templates/Services';
import Customer from './templates/Customer';
import Customer_Reviews from './templates/Reviews';
import News from './templates/News';
import Our_client from './templates/Our-Client';

const Home = () => {
    return(
        <>
            <Banner />
            <Offer />
            <Our_progress />
            <Services />
            <Customer />
            <Customer_Reviews />
            <Our_client />
        </>
    )
}

export default Home;