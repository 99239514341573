import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Outlet, Link } from "react-router-dom";
import Logo from '../../assets/images/Site-Logo.webp';
import './Header.css';


const Header = () =>{
   
  
    const [isSticky, setIsSticky] = useState(false);

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const stickyThreshold = 100;
        if (scrollPosition > stickyThreshold) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    //Active Menu
    const [isActive, setIsActive] = useState(false);

    const toogleMenu = (e) => {
        e.preventDefault();
        setIsActive(!isActive); // Toggle active state
    };

    const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get("https://themefantasy.com/json/header.json")
      .then((response) => setData(response.data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

    return(
        <>
        
            <header className={`header-sec ${isSticky ? 'sticky' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="logo-wrp">
                                <figure role="none" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1000">
                                    <Link to="/">
                                        <img src={Logo} alt="Logo" className="logo" width="200" height="48"/>
                                    </Link>
                                </figure>
                                <div class="menu-icon-wrp">
                                <a href="#" onClick={toogleMenu}><i class="fa-solid fa-bars"></i></a>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-8">
                            <nav className={`menu-wrp ${isActive ? "active-menu" : ""}`}>
                                <ul role="menu">
                                    <li role="menuitem" data-aos="fade-down" data-aos-delay="500" data-aos-duration="1000" onClick={toogleMenu}>
                                        <Link to="/" aria-label="Home" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });}}>Home</Link>
                                    </li>
                                    <li role="menuitem" data-aos="fade-down" data-aos-delay="700" data-aos-duration="1000" onClick={toogleMenu}>
                                        <Link to="/about-us/" aria-label="About Us" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });}}>About Us</Link>
                                    </li>
                                    <li role="menuitem" data-aos="fade-down" data-aos-delay="1000" data-aos-duration="1000" onClick={toogleMenu}>
                                        <Link to="/services/" aria-label="Services" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });}}>Services</Link>
                                    </li>
                                    <li role="menuitem" data-aos="fade-down" data-aos-delay="1200" data-aos-duration="1000" onClick={toogleMenu}>
                                        <Link to="/one-of-services/" aria-label="One Off-Services" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });}}>One Off-Services</Link>
                                    </li>
                                    <li role="menuitem" data-aos="fade-down" data-aos-delay="1500" data-aos-duration="1000" onClick={toogleMenu}>
                                        <Link to="/contact-us/" aria-label="Contact Us" onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });}}>Contact Us</Link>
                                    </li>
                                </ul>
                                <div class="close-menu-wrp">
                                    <a href="#" onClick={toogleMenu}><i class="fa-solid fa-xmark"></i></a>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;