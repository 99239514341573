import React from 'react';
import './Footer.css';
import FooterLogo from '../../assets/images/footer-logo.webp';

const Footer = () => {
    return(
        <>
        <footer className="footer-sec">
            <div className="newsletter-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="newsletter-cont-wrp">
                                <h2 data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">Subscribe to <br/>Our Newsletter</h2>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="newsletter-inner-wrp newsletter-form">
                                <form method="post" action="">
                                    <p data-aos="flip-right" data-aos-duration="1000" data-aos-delay="500">
                                        <input type="email" placeholder="Email" name="email" value="" class="email" required />
                                        <input type="button" class="submit-btn" value=""/> 
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className="footer-main-wrp">
                    <div class="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="social-media-wrp">
                                    <figure role="none" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="400">
                                        <img src={FooterLogo} alt="Logo" width="200" height="162"/>
                                    </figure>
                                    <ul>
                                        <li data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="400">
                                        <a href="https://x.com/">
                                            <span>
                                                <i class="fa-brands fa-twitter"></i>
                                            </span>
                                        </a>
                                        </li>
                                        <li data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800">
                                        <a href="https://www.facebook.com/">
                                            <span>
                                                <i class="fa-brands fa-facebook-f"></i>
                                            </span>
                                            </a>
                                        </li>
                                        <li data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1200">
                                            <a href="https://www.instagram.com/">
                                            <span>
                                                <i class="fa-brands fa-instagram"></i>
                                            </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="work-time-wrp">
                                    <h3 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">Our work time</h3>
                                    <ul>
                                        <li data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
                                            <span>Monday - Friday</span>
                                            <span>09:00 am - 17:00 pm</span>
                                        </li>
                                        <li data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1500">
                                            <span>Saturday</span>
                                            <span>10:00 am - 15:00 pm</span>
                                        </li>
                                        <li data-aos="fade-up" data-aos-duration="1500" data-aos-delay="2000">
                                            <span>Sunday</span>
                                            <span>We are closed</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="footer-address-wrp">
                                    <ul>
                                        <li>
                                            <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="400"><i class="fa-solid fa-location-dot"></i></span>
                                            <span data-aos="fade-left" data-aos-duration="1000" data-aos-delay="400">16/9, News Tata Colony, Bandra Kurla Comples RD, Bandra East, Mumbai, Maharastra 400051</span>
                                        </li>
                                        <li>
                                            <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800"><i class="fa-solid fa-envelope"></i></span>
                                            <span data-aos="fade-left" data-aos-duration="1000" data-aos-delay="800"><a href="mailto:info@themefantasy.com">info@themefantasy.com</a></span>
                                        </li>
                                        <li>
                                            <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1200"><i class="fa-solid fa-phone"></i></span>
                                            <span data-aos="fade-left" data-aos-duration="1000" data-aos-delay="1200"><a href="tel:+09773561830">+09773561830</a></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                        <div className="col-md-12">
                            <div className="copyright-inner-wrp">
                                <p>2024 Themefantasy All right reserved.</p>
                            </div>
                        </div>
                    </div>

                    
                    </div>
                </div>

        </footer>
        </>
    )
}

export default Footer;