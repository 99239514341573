import React from "react";

const ConnectWithUs = () => {
    return(
        <>
        <div className="connect-with-us-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title-wrp">
                            <h2 className="common-title" data-aos="fade-up" data-aos-duration="1500">Connect With Us</h2>
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">WE'D LOVE TO HEAR FROM YOU AND HAVE YOU IN PERSON AT OUR PHYSICAL LOCATIONS. IF YOU HAVE ANY QUERIES OR YOU WANT TO DISCUSS ANYTHING, BOTHER US AT ANY TIME.</p>
                        </div>
                    </div>
                </div>

<div className="connect-us-main-wrp">
                <div className="row">
                    <div className="col-md-6">
                        <div className="contact-list">
                            <ul>
                                <li data-aos="fade-right" data-aos-duration="1500">
                                    <span><i class="fa-solid fa-location-dot"></i></span>
                                    <span>16/9, News Tata Colony, Bandra Kurla Comples RD, Bandra East, Mumbai, Maharastra 400051</span>
                                </li>
                                <li data-aos="fade-right" data-aos-duration="1500" data-aos-delay="500">
                                    <span><i class="fa-solid fa-envelope"></i></span>
                                    <span><a href="mailto:info@themefantasy.com">info@themefantasy.com</a></span>
                                </li>
                                <li data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1000">
                                    <span><i class="fa-solid fa-phone"></i></span>
                                    <span><a href="tel:+09773561830">+09773561830</a></span>
                                </li>
                                <li data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1500">
                                    <span><i class="fa-solid fa-clock"></i></span>
                                    <span><p>Mon-Fri 9:00-17:00</p><p>Sunday we closed</p> </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="googlemap-wrp" data-aos="fade-up" data-aos-duration="1500">
                        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1600.8640106392334!2d72.85616037486193!3d19.062050002602113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8e0c619a359%3A0xff039fcead67df8!2sTata%20Colony!5e0!3m2!1sen!2sin!4v1728807046375!5m2!1sen!2sin"
        width="300"
        height="300"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ConnectWithUs;