import React from 'react';

const Blog = () => {
    return(
        <>
        <h1>Blog</h1>
        </>
    )
}

export default Blog;