const Offer = [
    {
        'icon':require('../../../../assets/images/offer-icon-1.webp'),
        'title':'Custom Plugin Development',
        'description':'Looking for WordPress Plugin Developer? Our highly experienced WordPress developers can develop & integrate plugin from the scratch.'
    },
    {
        'icon':require('../../../../assets/images/offer-icon-2.webp'),
        'title':'WordPress Theme Customization',
        'description':'Gets multi-dimensional WordPress services like plugin development, LMS, payment gateways, speed op- timization, and much more.'
    },
    {
        'icon':require('../../../../assets/images/offer-icon-3.webp'),
        'title':'LMS',
        'description':'Fully customized websites with signifi- cant marketing efforts that bring you the maximum business.'
    },
    {
        'icon':require('../../../../assets/images/offer-icon-4.webp'),
        'title':'Page Builder',
        'description':'Customize your WooCommerce store with our tailored services to meet your business needs and achieve the perfect look.'
    },

];

export default Offer;