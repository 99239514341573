import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import { Helmet } from 'react-helmet';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Components/Home-includes/template-home';
import About_us from './Components/About-includes/template-about';
import Services from './Components/Services-includes/template-services';
import One_of_services from './Components/One-Of-Services-includes/One-Of-Services';
import Blog from './Components/Blog-includes/template-blog';
import Contact from './Components/Contact-includes/template-contact';
import Single from './Components/Single/SinglePost';
import NoPage from './Components/NoPage'; 
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  AOS.init();
  return (
    <div className="App">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <BrowserRouter>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about-us" element={<About_us />} />
            <Route path="services" element={<Services />} />
            <Route path="one-of-services" element={<One_of_services />} />
            <Route path="blog" element={<Blog />} />
            <Route path="contact-us" element={<Contact />} />
            <Route path="single" element={<Single />} />
            <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
