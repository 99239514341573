import React, { useEffect, useRef } from "react";
import AOS from 'aos';
import Typed from "typed.js";
import 'aos/dist/aos.css';

const Banner = () => {
    const typedElement = useRef(null);

    useEffect(() => {
        const typed = new Typed(typedElement.current, {
            strings: ["Founded in 2011,"], 
            typeSpeed: 100,  
            backSpeed: 100,  
            backDelay: 1000,  
            loop: true, 
        });

        return () => {
            typed.destroy();
        };
    }, []);

    AOS.init();
    return(
        <>
        <div className="about-banner-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="banner-inner-wrp">
                            <h1 data-aos="fade-up" data-aos-duration="2000">About Us</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="about-banner-cont spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="about-cont-wrp">
                            <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500"><span ref={typedElement}></span> Themefantasy is a specializing in WordPress, Woocommerces, Magneto, and Shopify full-stack development. We've been working with WordPress & Woocommerce platform for more than a decade and <span>already won 500+ clients worldwide.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Banner;