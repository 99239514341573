import React from "react";
import brand_1 from '../../../assets/images/facebook-icon.webp';
import brand_2 from '../../../assets/images/google-icon.webp';
import brand_3 from '../../../assets/images/leafe-icon.webp';

const Customer = () => {
    return(
        <>
        <section className="customer-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="customer-inner-wrp">
                            <h2 class="common-title" data-aos="fade-up" data-aos-duration="2000">Customers Love Us</h2> 
                            <p data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">A website that sings your brand identity from the rooftops will make entrepreneurial life a ton easier. After all, we live in a world
                            where we Google before we shop, read reviews before we buy and 'check-in' wherever we go.</p>
                        </div>
                    </div>
                </div>

            <div className="brand-sec">
                <div className="row">
                    <div class="col-md-4">
                        <div className="brand-inner-box">
                            <figure role="none" data-aos="zoom-in" data-aos-duration="1000">
                                <img src={brand_1} alt="Facebook" width="90" height="89"/>
                            </figure>
                            <div className="rating-box">
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="400"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="600"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000"><i class="fa-solid fa-star"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="brand-inner-box">
                            <figure role="none" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300">
                                <img src={brand_2} alt="Google" width="90" height="91"/>
                            </figure>
                            <div className="rating-box">
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="400"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="600"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000"><i class="fa-solid fa-star"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="brand-inner-box">
                            <figure role="none" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="600">
                                <img src={brand_3} alt="Facebook" width="90" height="102"/>
                            </figure>
                            <div className="rating-box">
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="400"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="600"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="800"><i class="fa-solid fa-star"></i></span>
                                <span data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="1000"><i class="fa-solid fa-star"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Customer;