import React from 'react';
import './templates/All-Service.css';
import Banner from './templates/Banner';
import Offer from './templates/Offer';
import Reviews from '../Home-includes/templates/Reviews';
import Customer_love from '../Home-includes/templates/Customer';
import Portfolio from './templates/Portfolio';

const One_of_services = () => {
    return(
        <>
        <Banner />
        <Offer />
        <Customer_love />
        <Reviews />
        <Portfolio />
        </>
    )
}

export default One_of_services;