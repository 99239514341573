import React from "react";
import SocialMediaImage from '../../../assets/images/social-media-img.webp';
const SocialMedia = () => {
    return(
        <>
        <div className="social-media-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="social-media-img-wrp">
                            <figure role="none" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                                <img src={SocialMediaImage} alt="Social Media" />
                            </figure>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="social-media-cont-wrp">
                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">Themefantasy has no parallel when it comes to WordPress & Woo- Commerce development and customization services. We deal in Website Development, Web Designing, Mobile App Development, WordPress Custom Plugins, WordPress Themes, WordPress Frame- works, Custom CMS Development, E-Commerce Solutions, Payment Gateway Integration, API Programming, and Digital Media services.</p>
                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">Themefantasy has already delivered 2000+ praiseworthy projects to various clients belonging to different industries. Our busi- ness-strategic partnerships are located in North America, Canada, Europe, and the Middle East.</p>
                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">Our list of services also includes WordPress SaaS development, WordPress backend development (PHP, JavaScript), WordPress frontend development (HTML5, CSS3, AJAX), speed optimization & caching, SEO services, Uptime monitoring, security, malware re- moval, code reviews, database audit, performance troubleshooting, API programming, Payment gateway integration, website migration, and WordPress development retainers.</p>
                            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">We provide technical WordPress development, consultation, and support services to several corporations, global brands, and enter- prises. Our agency team takes care of your WordPress mainte- nance, content updates, daily offsite backups, various customiza- tions, and the look and feel of your site. We believe in building long-term relationships with our clients.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SocialMedia;