const OfferData = [
    {
        icon:require('../../../assets/images/offer-icon-1.webp'),
        title:'WordPress Plugins Development',
        description:'We build custom WordPress plugins from scratch to add unique functional- ity to your website or sell products to your potential customers.',
    },
    {
        icon:require('../../../assets/images/theme-customization.webp'),
        title:'WordPress Theme Customization',
        description:'Gets multi-dimensional WordPress ser- vices like plugin development, LMS, pay- ment gateways, speed optimization, and much more.',
    },
    {
        icon:require('../../../assets/images/LMS.webp'),
        title:'LMS',
        description:'Create your e-learning management system with us and provide more prac- tical knowledge in an efficient manner.',
    },
    {
        icon:require('../../../assets/images/woocommerce.webp'),
        title:'WooCommerce Services',
        description:'The top-notch resource for securable, practical training and support on build- ing a successful membership business model.',
    },
    {
        icon:require('../../../assets/images/Wordpress.webp'),
        title:'WordPress',
        description:'WordPress is massively growing and has already become the most popular open-source content management system (CMS). Millions of businesses, SMEs, and blogger',
    },
    {
        icon:require('../../../assets/images/page-builder.webp'),
        title:'Page Builder',
        description:'Fully customized websites with signifi- cant marketing efforts that bring you the maximum business.',
    },
    {
        icon:require('../../../assets/images/wordpress-form-services.webp'),
        title:'WordPress Forms Services',
        description:'Want to Build a Loyal Consumer Base? Stop Losing Valuable Leads and Grow Your Business with Professional Contact Forms!',
    },
    {
        icon:require('../../../assets/images/wordpress-migration.webp'),
        title:'WordPress Migration',
        description:'Migration from other CMS to WordPress, migration to a new hosting company. and WordPress data migration from one site to another.',
    },
    {
        icon:require('../../../assets/images/WP-maintenance.webp'),
        title:'WP Maintenance',
        description:'WordPress website maintenance and support services that build credibility and enhances digital growth.',
    },
    {
        icon:require('../../../assets/images/wordpress-payment-gateway.webp'),
        title:'WordPress Payments Gateways',
        description:'Our experts provide top-of-the-line payment gateway solutions to our sus- tainable and potential customers.',
    },
    {
        icon:require('../../../assets/images/wordpress-speed-optimization.webp'),
        title:'WordPress Speed Optimization',
        description:'Optimize and improve your WordPress website load-time using cache clear- ing. Caching solutions store a static version of a site',
    },
    {
        icon:require('../../../assets/images/wordpress-event.webp'),
        title:'WordPress Events',
        description:'If you want to achieve some of the re- markable calendar features, WordPress Events plugins are highly required.',
    },
    {
        icon:require('../../../assets/images/wordpress-multisite.webp'),
        title:'WordPress Multisite',
        description:'WordPress Multisite is a feature that en- ables you to manage multiple sites from a single installation, and this makes things a bit tricker.',
    },
    {
        icon:require('../../../assets/images/woocommerce.webp'),
        title:'WooCommerce Migration',
        description:'Are you frustrated with your current eCommerce platform for your eStore and want to migrate to WooCom- merce?',
    },
    {
        icon:require('../../../assets/images/woocommerce.webp'),
        title:'WordPress eCommerce',
        description:'WordPress also features plugins that add complete eCommerce functional- ity to a site, from marketing automation to sales funnel creations.',
    },
    {
        icon:require('../../../assets/images/wordpress-frameworks.webp'),
        title:'WordPress Frameworks',
        description:'WordPress frameworks are the founda- tion for a WordPress theme. Typically, the framework contains the core code, which covers the basic features and design elements.',
    },
    {
        icon:require('../../../assets/images/gamification-website.webp'),
        title:'WordPress Gamification Websites',
        description:'Gamification in web design seeks to in- clude elements of games such as scor- ing points, completing quests or mis- sions, clearing levels, accomplishing objectives..',
    },
    {
        icon:require('../../../assets/images/wordpress-job-board.webp'),
        title:'WordPress Job Board',
        description:'Provides professional consultancy to choose appropriate Job Board plugin and customize it for your exclusive re- quirements.',
    },
    {
        icon:require('../../../assets/images/wordpress-membership.webp'),
        title:'WordPress Membership Sites',
        description:'Ability to improve the membership model as afore frontier of freedom, flex- ibility, and fulfillment.',
    },
    {
        icon:require('../../../assets/images/multilingual-web.webp'),
        title:'WordPress Multilingual Websites',
        description:'WordPress multilingual websites offer the same content in several languages.',
    },
    {
        icon:require('../../../assets/images/premium-themes.webp'),
        title:'WordPress Premium Themes',
        description:'We deploy the latest tools and technol- ogies for WordPress theme customiza- tion and deliver top-quality service.',
    },
    {
        icon:require('../../../assets/images/wordpress-social.webp'),
        title:'WordPress Social',
        description:'Add user profiles, share images and videos, like comments, private messag- ing, groups, and more to your site.',
    },
    {
        icon:require('../../../assets/images/API-Programming.webp'),
        title:'ΑΡΙ Programming',
        description:'APIs are the key foundation of any digi- tal business. Enhance your business functions with API programming ser- vices that unlock the true value of your digital assets.',
    },
    {
        icon:require('../../../assets/images/Gutenberg.webp'),
        title:'Gutenberg',
        description:'Gutenberg is an entirely new paradigm in WordPress site development and publishing intended to transform the overall publishing exposure as Guten- berg did the printed word.',
    },
    {
        icon:require('../../../assets/images/Bookings-Websites.webp'),
        title:'WordPress Bookings Websites',
        description:'WordPress booking plugin with all the latest technological dimensions, latest design, and user experience strategies.',
    },
    {
        icon:require('../../../assets/images/Affiliates-websites.webp'),
        title:'WordPress Affiliates Websites',
        description:'When it comes to setting up an affliliate marketing program, Wordpress is the perfect platform',
    },
    {
        icon:require('../../../assets/images/wordpress-hosting.webp'),
        title:'WordPress Hosting',
        description:'When we talk about hosting, there are multiple options available in the mar- ketplace? But the question arises',
    },
]

export default OfferData;