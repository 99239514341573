import React from 'react';
import Slider from "react-slick";
//Review JS
import Reviews from '../js/reviews';

const Review = () => {
    var settings = {
        dots: false,
        infinite: true,
        nav: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992, // Adjust the breakpoint to match your mobile screen size preference
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768, // Adjust the breakpoint to match your mobile screen size preference
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    
    return(
        <>
        <section className="review-sec spacer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="review-inner-wrp">
                        <h2 class="common-title" data-aos="fade-up" data-aos-duration="2000">Customer Reviews</h2>
                        <Slider {...settings}>
                            {Reviews.map((ReviewItem, index) => (
                                <div className="slider-item" key={index} data-aos="fade-up" data-aos-duration="1000" data-aos-delay={index * 500}>
                                <div className="star-rating">
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                    <span><i class="fa-solid fa-star"></i></span>
                                </div>
                                <div className="review-message">
                                <p>{ReviewItem.description}...</p>
                                </div>
                                <div className="username">
                                    <h4>{ReviewItem.username}</h4>
                                </div>
                                <div className="user-profile-wrp">
                                    <figure role="none" >
                                        <img src={ReviewItem.profile} alt="Georgie Smith" width="64" height="64"/>
                                    </figure>
                                </div>
                            </div>
                            ))}
                        </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 
        </>
    )
}

export default Review;