const our_progress = [
    {
        'icon':require('../../../../assets/images/op-icon-1.webp'),
        'title':"Quick Setup",
        'description':"We'll go ahead and safely migrate your website over to our maintenance platform, without any downtime."
    },
    {
        'icon':require('../../../../assets/images/op-icon-2.webp'),
        'title':"Share Login Details",
        'description':'Easily share the login details for your existing host- ing via our custom encrypted "share access" tool.'
    },
    {
        'icon':require('../../../../assets/images/op-icon-3.webp'),
        'title':"Relax!",
        'description':"Congratulations, your website will be taken care of by our WordPress experts every day. Now you can relax!"
    },

];

export default our_progress;